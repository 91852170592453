import React, { useEffect } from 'react'
import '../../static/bootstrap.min.css'
import '../styles/style.scss'

function Template(props) {
  const preventDragHandler = (e) => {
    e.preventDefault();
  };

  // Important for hightlight current menu page
  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('wrapper-name', props.children[1].props.id)
    }
  }, [props.children])

  let children = props.children
  return (
    <div className="siteContent" role="row" tabIndex={0} onDragStart={(e) => preventDragHandler(e)}>
      {children}
    </div>
  )
}

export default Template
