import React from 'react'
import { InlineWidget } from 'react-calendly'

export default function Calendly(props) {
  const keyPress = () => {}
  return (
    <div id="popup-calendly" className={ props.data === true ? 'open' : '' } role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => props.closePopup(false)}>
      <div className="close" role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => props.closePopup(false)}/>
      {props.data === true && <InlineWidget className="calendly-inline-widget" url="https://calendly.com/flitdesk/flitdesk-intro" />}
    </div>
  )
}