import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Calendly from './Calendly'

function Item({ item }) {
  return (
    <div className="row">
      <div className="columns col-lg-2">
        <div className="box-icon">
          <img src={item.icone.file.url} alt=""/>
        </div>
      </div>
      <div className="columns col-lg-10"><p>{item.titre}<br/><span>{item.baselineMenu.baselineMenu}</span></p></div>
    </div>
  );
}

function Header(props) {
  const [btnMenuMobile, setBtnMenuMobile] = useState(false)
  const [language, setLanguage] = useState('1')
  const [headerMobile, setHeaderMobile] = useState(false)
  const [popupCalendly, setPopupCalendly] = useState(false)
  const [scroll, setScroll] = useState(null)
  const [top, setTop] = useState(null)
  const [height, setHeight] = useState(null)
  const [wrapperName, setWrapperName] = useState(null)

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  // Toggle language du desktop/mobile footer
  const toggleLanguage = () => {
    if (language === 0) {
      props.getLanguage('1')
      sessionStorage.setItem('langFooter', 'french')
      localStorage.setItem('language', '1')
      setLanguage(1)
    } else if (language === 1) {
      props.getLanguage('0')
      sessionStorage.setItem('langFooter', 'english')
      localStorage.setItem('language', '0')
      setLanguage(0)
    }
  }

  useEffect(() => {
    if (props.language === 1) {
      if (typeof window !== 'undefined') {
        const l = window.location
        if (l.href.indexOf('/en/pricing') > -1) {
          l.href = '/fr/tarifs'
        } else if (l.href.indexOf('/en/features') > -1) {
          l.href = '/fr/fonctionnalites'
        } else if (l.href.indexOf('/en/about') > -1) {
          l.href = '/fr/a-propos'
        } else if (l.href.indexOf('/en/solution-enterprise') > -1) {
          l.href = '/fr/solution-entreprise'
        } else if (l.href.indexOf('/en/solution-asset-managers') > -1) {
          l.href = '/fr/solution-gestionnaires-immobiliers'
        } else if (l.href.indexOf('/en/solution-coworking') > -1) {
          l.href = '/fr/solution-coworking'
        } else if (l.href.indexOf('/en/solution-startups') > -1) {
          l.href = '/fr/solution-startups'
        } else if (l.href.indexOf('/en/integration') > -1) {
          l.href = '/fr/integration'
        } else if (l.href.indexOf('/en/terms-of-use') > -1) {
          l.href = '/fr/conditions-generales-vente'
        } else if (l.href.indexOf('/en/privacy-policy') > -1) {
          l.href = '/fr/politique-confidentialite'
        } else if (l.href.indexOf('/en/') > -1) {
          l.href = '/fr/'
        }
      }
    } else if (props.language === 0) {
      if (typeof window !== 'undefined') {
        const l = window.location
        if (l.href.indexOf('/fr/tarifs') > -1) {
          l.href = '/en/pricing'
        } else if (l.href.indexOf('/fr/fonctionnalites') > -1) {
          l.href = '/en/features'
        } else if (l.href.indexOf('/fr/a-propos') > -1) {
          l.href = '/en/about'
        } else if (l.href.indexOf('/fr/solution-entreprise') > -1) {
          l.href = '/en/solution-enterprise'
        } else if (l.href.indexOf('/fr/solution-gestionnaires-immobiliers') > -1) {
          l.href = '/en/solution-asset-managers'
        } else if (l.href.indexOf('/fr/solution-coworking') > -1) {
          l.href = '/en/solution-coworking'
        } else if (l.href.indexOf('/fr/solution-startups') > -1) {
          l.href = '/en/solution-startups'
        } else if (l.href.indexOf('/fr/integration') > -1) {
          l.href = '/en/integration'
        } else if (l.href.indexOf('/fr/conditions-generales-vente') > -1) {
          l.href = '/en/terms-of-use'
        } else if (l.href.indexOf('/fr/politique-confidentialite') > -1) {
          l.href = '/en/privacy-policy'
        } else if (l.href.indexOf('/fr/') > -1) {
          l.href = '/en/'
        }
      }
    }
  }, [props.language])

  const handleScroll = e => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    //Scroll listner changing header background color
    const el = document.querySelector('nav')
    setTop(el.offsetTop)
    setHeight(el.offsetHeight)
    window.addEventListener('scroll', handleScroll)

    if (typeof sessionStorage !== 'undefined') {
      setWrapperName(localStorage.getItem('wrapper-name'))
      if((sessionStorage.getItem('langFooter') === 'english') || (localStorage.getItem('language') === '0') || (window.location.href.indexOf('/en/') > -1) || (props.language === 0)) {
        localStorage.setItem('language', '0')
        sessionStorage.setItem('langFooter', 'english')
        setLanguage(0)
      } if((sessionStorage.getItem('langFooter') === 'french') || (localStorage.getItem('language') === '1') || (window.location.href.indexOf('/fr/') > -1) || (props.language === 1)) {
        localStorage.setItem('language', '1')
        sessionStorage.setItem('langFooter', 'french')
        setLanguage(1)
      }
    }

		scroll > top ?
			document.body.style.paddingTop = `${height}px` :
      document.body.style.paddingTop = 0

  }, [height,top,scroll,props.language])

  const keyPress = () => {}

  const menu = props.headerContent

  return (
    <div>
    <header className={`${scroll > top ? ' fixed' : ''}`}>
      <div
        id="btn-menu-mobile"
        className={btnMenuMobile === true ? 'open' : ''}
        role="row"
        tabIndex={0}
        onKeyPress={keyPress}
        onClick={(e) => {setBtnMenuMobile(!btnMenuMobile); setHeaderMobile(!headerMobile)}}>
        <span></span>
        <span></span>
      </div>

      <nav role="navigation">
        <Link to={language === 0 ? '/en/' : '/fr/'} className={`logo ${headerMobile === true ? 'open' : ''}`}><img src="../logos/logo-flitdesk.svg" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></Link>
        <ul className={`menu-primaire ${headerMobile === true ? 'open' : ''}`}>
          <li className="has-childs">
            <p className={wrapperName === 'wrapper-solutions' ? 'current' : ''}>Solutions</p>
            <ul className="sub-menu">
              {menu
                .filter((item) => language === 0 && item.node_locale === 'en-US' && item.contentful_id !== '79zyChmhVkYyeBwxJFC5rr' && item.contentful_id !== '1x78apMBFX8zykFw9WKs9t' && item.contentful_id !== '4YSYRb9d0M2SNk8rN8Iu3d')
                .map((item) =>
                  <li key={item.contentful_id}>
                    <Link to={item.contentful_id === '63vJiAQ0tvdjBTbPoTyfeN' ? '/en/solution-startups' :
                        item.contentful_id === '5Wo5gp8fT8qhV3xnXP6qZT' ? '/en/solution-asset-managers' :
                        item.contentful_id === 'BuAy8h4OCkWq2llrtwSrD' ? '/en/solution-enterprise' :
                        item.contentful_id === '6GmGGdqwXtYLkChDqTcTu9' ? '/en/solution-coworking' : ''
                      }>
                      <Item item={item} key />
                    </Link>
                  </li>
                )}
              {menu
                .filter((item) => language === 1 && item.node_locale === 'fr' && item.contentful_id !== '79zyChmhVkYyeBwxJFC5rr' && item.contentful_id !== '1x78apMBFX8zykFw9WKs9t' && item.contentful_id !== '4YSYRb9d0M2SNk8rN8Iu3d')
                .map((item) =>
                  <li key={item.contentful_id}>
                    <Link to={item.contentful_id === '63vJiAQ0tvdjBTbPoTyfeN' ? '/fr/solution-startups' :
                        item.contentful_id === '5Wo5gp8fT8qhV3xnXP6qZT' ? '/fr/solution-gestionnaires-immobiliers' :
                        item.contentful_id === 'BuAy8h4OCkWq2llrtwSrD' ? '/fr/solution-entreprise' :
                        item.contentful_id === '6GmGGdqwXtYLkChDqTcTu9' ? '/fr/solution-coworking' : ''
                      }>
                      <Item item={item} key />
                    </Link>
                  </li>
                )}
            </ul>
          </li>
          <li><Link to={language === 0 ? '/en/features' : language === 1 ? '/fr/fonctionnalites' : ''} className={wrapperName === 'wrapper-features' ? 'current' : ''}>{language === 0 ? 'Features' : 'Fonctionnalités'}</Link></li>
          <li><Link to={language === 0 ? '/en/integration' : language === 1 ? '/fr/integration' : ''} className={wrapperName === 'wrapper-integration' ? 'current' : ''}>{language === 0 ? 'Integrations' : 'Intégrations'}</Link></li>
          {/* For the moment we hide this */}
          {/* <li><Link to={this.state.language === 0 ? '/en/pricing' : this.state.language === 1 ? '/fr/tarifs' : ''} className={this.state.wrapperName === 'wrapper-pricing' ? 'current' : ''}>{this.state.language === 0 ? 'Pricing' : 'Tarifs'}</Link></li> */}
          <li className="has-childs">
            <p className={wrapperName === 'wrapper-about' ? 'current' : ''}>{language === 0 ? 'About' : 'À Propos'}</p>
            <ul className="sub-menu">
              {menu
                .filter((item) => (language === 0 && item.node_locale === 'en-US') && ((item.contentful_id === '1x78apMBFX8zykFw9WKs9t') || (item.contentful_id === '4YSYRb9d0M2SNk8rN8Iu3d' || item.contentful_id === '79zyChmhVkYyeBwxJFC5rr')))
                .map((item) =>
                    <li key={item.contentful_id}>
                      {item.contentful_id === '79zyChmhVkYyeBwxJFC5rr' &&
                      <a href='https://blog.flitdesk.com/' rel="noopener noreferrer" target="_blank">
                        <Item item={item} key />
                      </a>}
                      {item.contentful_id === '4YSYRb9d0M2SNk8rN8Iu3d' &&
                      <a href='https://mailchi.mp/d087db108509/flitdesk-newsletter' rel="noopener noreferrer" target="_blank">
                        <Item item={item} key />
                      </a>}
                      {item.contentful_id === '1x78apMBFX8zykFw9WKs9t' &&
                      <Link to='/en/about'>
                        <Item item={item} key />
                      </Link>}
                    </li>
                  )}
                {menu
                  .filter((item) => (language === 1 && item.node_locale === 'fr') && ((item.contentful_id === '1x78apMBFX8zykFw9WKs9t' || item.contentful_id === '4YSYRb9d0M2SNk8rN8Iu3d' || item.contentful_id === '79zyChmhVkYyeBwxJFC5rr')))
                  .map((item) =>
                    <li key={item.contentful_id}>
                      {item.contentful_id === '79zyChmhVkYyeBwxJFC5rr' &&
                      <a href='https://blog.flitdesk.com/' rel="noopener noreferrer" target="_blank">
                        <Item item={item} key />
                      </a>}
                      {item.contentful_id === '4YSYRb9d0M2SNk8rN8Iu3d' &&
                      <a href='https://mailchi.mp/d087db108509/flitdesk-newsletter' rel="noopener noreferrer" target="_blank">
                        <Item item={item} key />
                      </a>}
                      {item.contentful_id === '1x78apMBFX8zykFw9WKs9t' &&
                      <Link to='/fr/a-propos'>
                        <Item item={item} key />
                      </Link>}
                    </li>
                  )}
            </ul>
          </li>
          {/* <li><Link to="/">{this.state.language === 0 ? 'Login' : 'Se connecter'}</Link></li> */}
          <li><button className="btn btn-primary" onClick={(e) => setPopupCalendly(true)}><span>{language === 0 ? 'Schedule a demo' : 'Planifier une démo'}</span></button></li>
          <li className="lang">
            <div
              role="row"
              tabIndex={0}
              onKeyPress={keyPress}
              className={language  === 0 ? 'french checked' : language  === 1 ? 'english checked' : ''}
              onClick={(e) => toggleLanguage()}>
            </div>
          </li>
        </ul>
      </nav>
    </header>
    <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
    </div>
  )
}

export default Header