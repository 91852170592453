module.exports = {
  siteTitle: 'Flitdesk',
  siteTitleAlt: 'Flitdesk', // This allows an alternative site title for SEO schema.
  publisher: 'Flitdesk', // Organization name used for SEO schema
  siteDescription: 'Flitdesk is the all-in-one solution to simplify the management of offices and modern workspaces',
  siteUrl: 'https://flitdesk.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Flitdesk', // Author for RSS author segment and SEO schema
  userTwitter: '@flitdesk', // Change for Twitter Cards
  shortTitle: 'Flitdesk', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/favicon-196x196.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#ff5a5a', // Used for Offline Manifest
  copyright: 'Copyright © 2020 Flitdesk', // Copyright string for the RSS feed
}
